import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getEMail, getToken, usePermissions, useUser } from "../../user/User"
import FormButton from "../../components/FormButton"
import { toast } from "react-toastify"
import { useEffect } from "react"
import Permissions from "./Permissions"

const UserEdit = () => {
    const navigate = useNavigate()

    let { uuid } = useParams()
    let { name, setName, email } = useUser(uuid)
    const { permissions: userPermissions, setPermissions: setUserPermissions } = usePermissions(email)
    const { permissions } = usePermissions(getEMail())

    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("Du hast keine Erlaubnis Benutzer zu bearbeiten!")
            navigate("/")
        }
    }, [permissions])

    async function saveUser() {
        axios.put(process.env.REACT_APP_API_URL + "/api/users/" + uuid, JSON.stringify({
            "email": email,
            "name": name
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            savePermissions()
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Der Benutzer mit der UUID " + uuid + " wurde nicht gefunden.")
                    return
                } else if(statusCode === 409) {
                    toast.error("Es existiert bereits ein Benutzer mit diesem Namen bzw. dieser UUID.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    async function savePermissions() {
        axios.put(process.env.REACT_APP_API_URL + "/api/permissions/" + email, JSON.stringify({
            "email": email,
            "permissions": userPermissions
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Benutzer " + name + " gespeichert.")
            navigate("/user/" + name)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404 || statusCode === 406) {
                    toast.error("Der Benutzer mit der E-Mail-Adresse " + email + " wurde nicht gefunden.")
                    return
                }
            }
            toast.error("Serverfehler.")
        })
    }

    async function deleteUser() {
        axios.delete(process.env.REACT_APP_API_URL + "/api/users/" + uuid, {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Benutzer " + name + " gelöscht.")
            navigate("/user")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404 || statusCode === 406) {
                    toast.error("Der Benutzer mit der UUID " + uuid + " wurde nicht gefunden.")
                    return
                }
            }
            toast.error("Serverfehler.")
        })
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/user")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        saveUser()
    }

    const handleDelete = async e => {
        e.preventDefault()
        deleteUser()
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Zurück zur Übersicht</button>

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="E-Mail-Adresse" id="email" required="required" value={email} disabled="disabled" icon="fa-solid fa-envelope" />
                            <FormButton label="Minecraft Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-gamepad-modern" />
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="col-12">
                            <Permissions data={userPermissions} setData={setUserPermissions} />
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Benutzer speichern</button> <button className="btn btn-danger" onClick={handleDelete}>Benutzer löschen</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default UserEdit