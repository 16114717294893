import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getToken, useUser } from "../../user/User"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { useEffect, useState } from "react"

const PointsEdit = () => {
    const navigate = useNavigate()
    
    const [ pointStats, setPointStats ] = useState([])
    let { uuid } = useParams()
    let { name } = useUser(uuid)

    async function savePoints() {
        let pointStatsJson = []
        pointStats.forEach(stats => {
            pointStatsJson.push({
                "point_id": stats.id,
                "player_uuid": uuid,
                "value": stats.points,
            })
        })
        axios.put(process.env.REACT_APP_API_URL + "/api/points/" + uuid, JSON.stringify(pointStatsJson), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(_ => {
            toast.success("Punkte aktualisiert.")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    function updateStats(arg, value) {
        const newPointStats = pointStats.map(point => {
            if (point.id == arg) {
                return {...point, points: value}
            }
            return point
        })
        setPointStats(newPointStats)
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/points")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        savePoints()
    }

    useEffect(() => {
        if(uuid == undefined) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/points/" + uuid, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            res.data.forEach(pointsEntry => {
                pointsEntry.stats.forEach(statsEntry => {
                    setPointStats(oldArray => [...oldArray, {id: pointsEntry.id, name: pointsEntry.name, points: statsEntry.points}])
                })
            })
        }).catch(e => {
            console.log(e)
            toast.error("Punkte konnten nicht geladen werden.")
        })
    }, [uuid])

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Zurück zur Übersicht</button>

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <h3>{ name }</h3>

                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            {pointStats.map(point => (
                                <div key={point.id}><FormButton label={point.name} id={point.id} value={point.points} setValue={updateStats} updateArgument={point.id} type="number" icon="fa-solid fa-hundred-points" required="required" /></div>
                            ))}
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-success">Punkte aktualisieren</button>
            </form>
        </>
    )
}
   
export default PointsEdit