import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useServer = (id) => {
    const [name, setName] = useState(id)
    const [queryHostname, setQueryHostname] = useState("")
    const [queryPort, setQueryPort] = useState(25565)
    const [sshHostname, setSshHostname] = useState("")
    const [sshPort, setSshPort] = useState(22)
    const [sshUsername, setSshUsername] = useState("")
    const [sshPassword, setSshPassword] = useState("")
    const [sshKey, setSshKey] = useState("")
    const [screenName, setScreenName] = useState("")
    const [folder, setFolder] = useState("")
    const [stopCommand, setStopCommand] = useState("")
    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/servers/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(res => {
                setName(res.data.name)
                setQueryHostname(res.data.query_hostname)
                setQueryPort(res.data.query_port)
                setSshHostname(res.data.ssh_hostname)
                setSshPort(res.data.ssh_port)
                setSshUsername(res.data.ssh_username)
                setSshPassword(res.data.ssh_password)
                setSshKey(res.data.ssh_key)
                setScreenName(res.data.screen_name)
                setFolder(res.data.folder)
                setStopCommand(res.data.stop_command)
            }).catch(error => {
                if(error.message === "canceled") {
                  return
                }
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Ungültige Anfrage.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Es existiert kein Server mit der Id " + id + ".")
                        return
                    }
                }
                toast.error("Serverfehler")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, name, setName, queryHostname, setQueryHostname, queryPort, setQueryPort, sshHostname, setSshHostname, sshPort, setSshPort, sshUsername, setSshUsername, 
        sshPassword, setSshPassword, sshKey, setSshKey, screenName, setScreenName, folder, setFolder, stopCommand, setStopCommand }
}