import axios from "axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { getToken } from "../../user/User"

const MoneyMovementList = ({filter}) => {
    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Erstellt",
        accessor: (row, _) => {
            return new Date(row.created).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Richtung",
        accessor: "direction",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Ursache",
        accessor: "cause",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Geld",
        accessor: "value",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]

    const [moneyMovements, setMoneyMovements] = useState([])
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/moneymovements" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setMoneyMovements(res.data)
        }).catch(_ => {
            toast.error("Geldbewegungen konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={moneyMovements} editUrlPart="moneymovement" />
        </div>
    )
}
   
export default MoneyMovementList