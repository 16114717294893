import axios from "axios"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { getEMail, getToken, usePermissions } from "../../user/User"
import { Link, useNavigate } from "react-router-dom"

const AddressList = ({filter}) => {
    const navigate = useNavigate()
    
    const columns =  [{
        Header: "Name",
        accessor: "name"
    }, {
        Header: "IP Adresse",
        accessor: "address"
    }, {
        Header: "Score",
        accessor: "score",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]
    
    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.addresses !== undefined && !permissions.addresses) {
            toast.error("Du hast keine Erlaubnis IP Adressen zu sehen!")
            navigate("/")
        }
    }, [permissions])

    const [addresses, setAddresses] = useState([])
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/addresses" + (filter === undefined ? "" : "/" + filter), {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setAddresses(res.data)
        }).catch(_ => {
            toast.error("IP Adressen konnten nicht geladen werden.")
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={addresses} editUrlPart="address" />
        </div>
    )
}
   
export default AddressList