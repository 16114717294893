import { useEffect, useState } from 'react'

export const useTime = (sec, qualifiers) => {
    const [time, setTime] = useState("")
    useEffect(() => {
        var sec_num = sec
        var days    = Math.floor(sec_num / 86400)
        sec_num     -= days * 86400
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60)
        var seconds = sec_num - (hours * 3600) - (minutes * 60)
    
        var usedQualifiers = 0;
        var toReturn = ''
        if(days !== 0) {
            toReturn += days + ' Tage '
            usedQualifiers++
        }
        if(usedQualifiers < qualifiers && hours !== 0) {
            toReturn += hours + ' Stunden '
            usedQualifiers++
        }
        if(usedQualifiers < qualifiers && minutes !== 0) {
            toReturn += minutes + ' Minuten '
            usedQualifiers++
        }
        if(usedQualifiers < qualifiers && seconds !== 0) {
            toReturn += seconds + ' Sekunden '
        }
        setTime(toReturn.trim())
    }, [sec])

    return { time }
}