import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getEMail, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const ServerList = () => {
    const navigate = useNavigate()
    
    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.server !== undefined && !permissions.server) {
            toast.error("Du hast keine Erlaubnis Server zu sehen!")
            navigate("/")
        }
    }, [permissions])

    const [isLoading, setIsLoading] = useState(false)

    const handleRefresh = async e => {
        e.preventDefault()
        load()
    }

    const handleCreate = async e => {
        e.preventDefault()
        navigate("/server/create")
    }

    const handleAction = async (action, id) => {
        let actionN = ""
        let actionED = ""
        let command = ""
        switch(action) {
            case "start":
                actionN = "starten"
                actionED = "gestartet"
                break
            case "stop":
                actionN = "stoppen"
                actionED = "gestoppt"
                break
            case "restart":
                actionN = "neusarten"
                actionED = "neugestartet"
                break
            default:
                return
        }
        if(!window.confirm("Möchtest du den Server wirklich " + actionN + "?")) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/servers/" + id, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            const folder = res.data.folder
            const screenName = res.data.screen_name
            const stopCommand = res.data.stop_command
            
            switch(action) {
                case "start":
                    command = "rm -f " + folder + "stop"
                    break
                case "stop":
                    command = "touch "+ folder + "stop && screen -S " + screenName + " -p 0 -X stuff \"" + stopCommand + "^M\""
                    break
                case "restart":
                    command = "screen -S " + screenName + " -p 0 -X stuff \"" + stopCommand + "^M\""
                    break
                default:
                    return
            }

            axios.post(process.env.REACT_APP_API_URL + "/api/servers/execute", JSON.stringify({
                "server_id": id,
                "command": command
            }), {
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(_ => {
                toast.success("Der Server wird nun " + actionED + ".")
            }).catch(_ => {
                toast.error("Der Server konnte nicht " + actionED + " werden.")
            })
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Es existiert kein Server mit der Id " + id + ".")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    const handleOpenConsole = async serverId => {
        navigate("/server/console/" + serverId)
    }

    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Icon",
        Cell: tableProps => (
           <img src={tableProps.row.original.info.favicon} alt='' />
        )
    }, {
        Header: "Name",
        accessor: (row, _) => {
            return row.name + (row.status === "Gestartet"  ? " (" + row.info.motd + ")" : "")
        }
    }, {
        Header: "Aktuelle Spieler",
        accessor: (row, _) => {
            return row.info.players
        },
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Maximale Spieler",
        accessor: (row, _) => {
            return row.info.max_players
        },
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Status",
        accessor: (row, _) => {
            return row.status + (row.status === "Gestartet" ? " (" + row.info.latency + "ms)" : "")
        },
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Aktionen",
        Cell: tableProps => (
            <div>
                {tableProps.row.original.status === "Gestoppt" ? 
                    <button className="btn btn-success" onClick={() => handleAction("start", tableProps.row.original.id)}>Starten</button>
                    :
                    <div>
                        <button className="btn btn-danger" onClick={() => handleAction("stop", tableProps.row.original.id)}>Stoppen</button>
                        <button className="btn btn-warning" onClick={() => handleAction("restart", tableProps.row.original.id)}>Neustarten</button>
                    </div>
                }
                <button className="btn btn-secondary" onClick={() => handleOpenConsole(tableProps.row.original.id)}>Konsole</button>
            </div>
        )
    }, {
        Header: "Bearbeiten"
    }]

    const [servers, setServers] = useState([])

    function load() {
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_URL + "/api/servers", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            setIsLoading(false)
            if(res.data === null || res.data === "") {
              return
            }
            setServers(res.data)
        }).catch(_ => {
            toast.error("Server konnten nicht geladen werden.")
            setIsLoading(false)
        })
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Server erstellen</button>
            <button className="btn btn-secondary" onClick={handleRefresh}>Aktualisieren</button>

            {
                isLoading ? <h3>Lädt...</h3> :
                <BasicTableComponent columns={columns} data={servers} editUrlPart="server" />
            }
        </div>
    )
}
   
export default ServerList