import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { isLoggedIn } from "../user/User"
import { toast } from 'react-toastify'
import { useGitHubAuth } from '../github/GitHub'

const GitHubLogin = () => {
    let { isGitHubAuthEnabled } = useGitHubAuth()
    const params = new URLSearchParams(window.location.search);
    const gitHubAuthCode = params.get("code");

    const navigate = useNavigate()
    if (isLoggedIn()) {
        navigate("/")
        return
    }

    if(!isGitHubAuthEnabled || gitHubAuthCode == undefined) {
        navigate("/login")
        return
    }

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + "/login", JSON.stringify({
            "github_auth_code": gitHubAuthCode
        })).then(res => {
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('token', res.data)
            toast.success("Erfolgreich über GitHub eingeloggt.")
            navigate("/")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    navigate("/")
                    toast.error("Ungültige Anfrage.")
                } else if(statusCode === 401) {
                    navigate("/")
                    toast.error("GitHub Benutzer konnte nicht geladen werden oder ist nicht berechtigt.")
                }
                return
            }
            navigate("/")
            toast.error("Serverfehler.")
            return
        })
    })

    return "Redirecting..."
}
 
export default GitHubLogin