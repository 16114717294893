export const useGitHubAuth = () => {
    let clientId = process.env.REACT_APP_GITHUB_CLIENT_ID
    let redirectUrl = process.env.REACT_APP_GITHUB_REDIRECT_URL

    let isGitHubAuthEnabled = false
    let gitHubAuthURL = "https://github.com/login/oauth/authorize?client_id=" + clientId + "&redirect_uri" + redirectUrl + "&scope=user:email,read:org&allow_signup=false"
    
    if(clientId != undefined && redirectUrl != undefined) {
        isGitHubAuthEnabled = true
    }

    return { isGitHubAuthEnabled, gitHubAuthURL }
}