import axios from 'axios'
import jwt from 'jwt-decode'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const GetUsers = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        const abortCont = new AbortController()
        
        axios.get(process.env.REACT_APP_API_URL + "/api/users", {
            signal: abortCont.signal,
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            setUsers(res.data)
        }).catch(err => {
            if(err.message === "canceled") {
              return
            }
            toast.error("Benutzer konnten nicht geladen werden.")
        })
    
        return () => abortCont.abort()
    }, [])

    return { users }
}

export const useCurrentUser = () => {
    return useUser(getUUID())
}

export const useUser = (uuid) => {
    const [name, setName] = useState(uuid)
    const [email, setEmail] = useState("")
    useEffect(() => {
        if(uuid !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/users/" + uuid, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                setName(res.data.name)
                setEmail(res.data.email)
            }).catch(err => {
                if(err.message === "canceled") {
                  return
                }
                toast.error("Serverfehler")
            })
        
            return () => abortCont.abort()
        }
    }, [uuid])

    return { uuid, name, setName, email, setEmail }
}

export function getUUID() {
    var uuid = null
    const token = getTokenData()
    if(token !== null) {
        uuid = token.uuid
    }

    return uuid
}

export function getEMail() {
    var email = null
    const token = getTokenData()
    if(token !== null) {
        email = token.email
    }

    return email
}

export function isLoggedIn() {
    return getTokenData() !== null
}

export function getTokenData() {
    var token = getToken()
    if(token) {
        try {
            var data = jwt(token)
            if(data.exp > Math.floor(Date.now() / 1000)) {
                return data
            }
        } catch(e) {
        }
    }

    return null
}

export function getToken() {
    var token = sessionStorage.getItem('token')
    if(token === null) {
        token = localStorage.getItem('token')
    }

    return token
}

export const usePermissions = (email) => {
    const [permissions, setPermissions] = useState({})
    useEffect(() => {
        const abortCont = new AbortController()
        
        axios.get(process.env.REACT_APP_API_URL + "/api/permissions" + (email !== null && email !== "" ? "/" + email : ""), {
            signal: abortCont.signal,
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            setPermissions(res.data.permissions)
        }).catch(err => {
            if(err.message === "canceled") {
              return
            }
            toast.error("Serverfehler")
        })
    
        return () => abortCont.abort()
    }, [email])

    return { permissions, setPermissions }
}