import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getToken, getUUID } from "../../user/User"
import { useTicket } from "../../ticket/Ticket"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"

const TicketEdit = () => {
    const navigate = useNavigate()
    
    let { id } = useParams()
    let { createdFormatted, state, setState, stateText, setStateText, creatorName, setSupporter, supporterName, setSupporterName, server, world, x, y, z, category, request, answer, setAnswer } = useTicket(id)

    async function closeTicket() {
        axios.put(process.env.REACT_APP_API_URL + "/api/tickets/" + id, JSON.stringify({
            "state": "closed",
            "supporter_uuid": getUUID(),
            "answer": answer
        }), {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setState(res.data.state)
            setStateText(res.data.state_text)
            setSupporter(res.data.supporter)
            setSupporterName(res.data.supporter_name)
            toast.success("Ticket #" + id + " geschlossen.")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Das Ticket #" + id + " wurde nicht gefunden.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/ticket")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        closeTicket()
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Zurück zur Übersicht</button>

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Ersteller" id="creator" value={creatorName} disabled="disabled" icon="fa-solid fa-ticket" />
                            <FormButton label="Erstellt" id="created" value={createdFormatted} disabled="disabled" icon="fa-solid fa-clock" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Kategorie" id="category" value={category} disabled="disabled" icon="fa-solid fa-list-radio" />
                        </div>
                        <div className="col-12">
                            <FormButton label="Problem" id="request" value={request} disabled="disabled" icon="fa-solid fa-question" type="textarea"
                                size="col-sm-12 col-md-10 col-lg-10" labelSize="col-sm-12 col-md-2 col-lg-2" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Server" id="server" value={server} disabled="disabled" icon="fa-solid fa-server" />
                            <FormButton label="Position" id="position" value={x + ", " + y + ", " + z + " @ " + world} disabled="disabled" icon="fa-solid fa-location-dot" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Status" id="status" value={stateText} icon={"fa-solid " + (state === "open" ? "fa-unlock" : "fa-lock")} disabled="disabled" />
                            <FormButton label="Bearbeiter" id="supporter" value={supporterName} icon="fa-solid fa-user" disabled="disabled" />
                            { state === "open" ?
                                <FormButton label="Antwort" id="answer" value={answer} icon="fa-solid fa-comment" setValue={setAnswer}/>
                            :
                                <FormButton label="Antwort" id="answer" value={answer} icon="fa-solid fa-comment" disabled="disabled" />
                            }
                        </div>
                    </div>

                    { state === "open" ?
                        <div className="card-footer">
                            <button type="submit" className="btn btn-success">Ticket schließen</button>
                        </div>
                        : ""
                    }
                </div>
            </form>
        </>
    )
}
   
export default TicketEdit