import axios from "axios"
import { sha512 } from "crypto-hash"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getEMail, getToken, usePermissions } from "../../user/User"
import Permissions from './Permissions'

const UserCreate = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("Du hast keine Erlaubnis Benutzer zu erstellen!")
            navigate("/")
        }
    }, [permissions])

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { permissions: userPermissions, setPermissions: setUserPermissions } = usePermissions(email)

    async function createUser() {
        var passwordHash = await sha512(password)
        axios.post(process.env.REACT_APP_API_URL + "/api/users", JSON.stringify({
            "email": email,
            "name": name,
            "password": passwordHash
        }), {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            savePermissions()
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 409) {
                    toast.error("Es existiert bereits ein Benutzer mit diesem Namen bzw. dieser UUID.")
                    return
                } else if(statusCode === 406) {
                    toast.error("Der Benutzer konnte nicht erstellt werden.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    async function savePermissions() {
        axios.put(process.env.REACT_APP_API_URL + "/api/permissions/" + email, JSON.stringify({
            "email": email,
            "permissions": userPermissions
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Benutzer " + name + " erstellt.")
            navigate("/user/" + name)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                } else if(statusCode === 404 || statusCode === 406) {
                    toast.error("Der Benutzer mit der E-Mail-Adresse " + email + " wurde nicht gefunden.")
                    return
                }
            }
            toast.error("Serverfehler.")
        })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        createUser()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/user")
    }
    
    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Zurück zur Übersicht</button>

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="E-Mail-Adresse" id="email" required="required" value={email} setValue={setEmail} icon="fa-solid fa-envelope" />
                            <FormButton label="Minecraft Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-gamepad-modern" />
                            <FormButton label="Passwort" id="password" required="required" value={password} setValue={setPassword} icon="fa-solid fa-lock" type="password" minLength={8} />
                        </div>
                    </div>

                    <div className="card-body">
                        <Permissions data={userPermissions} setData={setUserPermissions} />
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Benutzer erstellen</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default UserCreate