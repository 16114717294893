import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getToken } from "../../user/User"
import Autocomplete from "react-autocomplete"
import TicketList from "../ticket/TicketList"
import CharacterHistoryList from "../character/CharacterHistoryList"
import PunishmentList from "../punishment/PunishmentList"
import ClientList from "../client/ClientList"
import AddressList from "../address/AddressList"
import PointsList from "../points/PointsList"
import { useTime } from "../../time/Time"
import { useParams } from "react-router-dom"

const PlayerSearch = () => {
    const [player, setPlayer] = useState("")
    const [users, setUsers] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [onlinetime, setOnlinetime] = useState(0)
    const { time } = useTime(onlinetime, 4)
    const [participantData, setParticipantData] = useState([])
    const [characterData, setCharacterData] = useState([])

    let optionalPlayer = useParams()
    useEffect(() => {
        if(optionalPlayer.player != null) {
            setPlayer(optionalPlayer.player)
            loadPlayer(optionalPlayer.player)
        }
    }, [optionalPlayer])

    async function updatePlayer(newPlayer) {
        setOnlinetime(0)
        setPlayer(newPlayer)
        setIsLoaded(false)
        if(newPlayer === "") {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/players/" + newPlayer, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setUsers(res.data)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Ungültige Anfrage.")
                    return
                }
            }
            toast.error("Serverfehler")
        })
    }

    const loadPlayer = (playerToLoad) => {
        if(playerToLoad == null) {
            playerToLoad = player
        }
        if(playerToLoad === "") {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/onlinetime/" + playerToLoad, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setOnlinetime(res.data[0].onlinetime)
        }).catch(e => {
            console.log(e)
            toast.error("Spielzeiten konnten nicht geladen werden.")
        })

        axios.get(process.env.REACT_APP_API_URL + "/api/participant/" + playerToLoad, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setParticipantData(res.data)
        }).catch(e => {
            console.log(e)
            toast.error("Spielerdaten konnten nicht geladen werden.")
        })

        axios.get(process.env.REACT_APP_API_URL + "/api/character/" + playerToLoad, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            if(res.data === null || res.data === "") {
              return
            }
            setCharacterData(res.data)
        }).catch(e => {
            console.log(e)
            toast.error("Charakterdaten konnten nicht geladen werden.")
        })

        setIsLoaded(true)
    }

    return (
        <div className="row">
            <div className="col-12" style={{zIndex: "100", marginBottom: "20px"}}>
                <Autocomplete
                    wrapperStyle={{cursor: "pointer", float: "left"}}
                    getItemValue={(item) => item.label}
                    items={users.map(user => {
                        return {label: user}
                    })}
                    renderItem={(item, isHighlighted) =>
                        <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            {item.label}
                        </div>
                    }
                    value={player}
                    onChange={(e) => updatePlayer(e.target.value)}
                    onSelect={(selected) => setPlayer(selected)}
                />
                <button type="submit" className="btn btn-primary" onClick={() => loadPlayer()}>Spieler laden</button>
            </div>

            {!isLoaded ? "" : 
            <>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Charakter</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                UUID: {participantData["uuid"] == null ? characterData.current == null ? "" : characterData.current.player_uuid : participantData["uuid"]}<br />
                                Name: {participantData["name"] == null ? characterData.current == null ? "" : characterData.current.player_name : participantData["name"]}<br />
                                Titel (Stand): {participantData["title"]} {participantData["state"] == null ? "" : "(" + participantData["state"] + ")"}<br />
                                Spielzeit: {time}<br />
                                <br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                {characterData.current == null ? "" : 
                                <>Charaktername: {characterData.current.name}<br />
                                Geschlecht: {characterData.current.geschlecht}<br />
                                Alter: {characterData.current.alter}<br />
                                Aussehen: {characterData.current.aussehen}<br /></>}
                                <br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Gefolge: {participantData["faction_name"]}<br />
                                Rang: {participantData["rank"]}<br />
                                Geld: {participantData["money"]}<br />
                                Steuerverwarnungen: {participantData["warns"]}<br />
                                <br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Ritter: {participantData["is_knight"] ? "Ja" : "Nein"}<br />
                                Exkommuniziert: {participantData["is_excommunicated"] ? "Ja" : "Nein"}<br />
                                Verfolgt: {participantData["is_hunted"] ? "Ja" : "Nein"}<br />
                                Eingesperrt: {participantData["is_jailed"] ? "Ja (" + participantData["jail_faction_name"] + ")" : "Nein"}<br />
                                <br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Mönch: {participantData["is_monk"] ? "Ja" : "Nein"}<br />
                                Priester: {participantData["is_priest"] ? "Ja" : "Nein"}<br />
                                Ist zugehörig: {participantData["is_associated"] ? "Ja (" + participantData["associated_faction_name"] + ")" : "Nein"}<br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Alte Charaktere</h3>
                    </div>
                    <div className="card-body">
                        <CharacterHistoryList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Clients</h3>
                    </div>
                    <div className="card-body">
                        <ClientList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Verwarnungen</h3>
                    </div>
                    <div className="card-body">
                        <PunishmentList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>IP Adressen</h3>
                    </div>
                    <div className="card-body">
                        <AddressList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Tickets</h3>
                    </div>
                    <div className="card-body">
                        <TicketList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Punkte</h3>
                    </div>
                    <div className="card-body">
                        <PointsList filter={player} />
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}
   
export default PlayerSearch